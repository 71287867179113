import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
const routes: Routes = [
    {
      path:'',
      loadComponent: () => import('@penji/marketplace-checkout/ui/layout').then(mod => mod.MarketplaceCheckoutUiLayoutComponent),
      children: [
        {
          path: '',
          loadComponent: () => import('@penji/marketplace-checkout/pages/default').then(mod => mod.StepsComponent),
        }
      ]
    }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    
  })],
  exports: [RouterModule]
})
export class MarketplaceCheckoutShellRoutingModule { }