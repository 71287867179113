import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getAnalytics, provideAnalytics } from '@angular/fire/analytics';
import { RouterModule } from '@angular/router';
import { NgxStripeModule } from 'ngx-stripe';
import { environment } from '@penji/shared/environments';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MarketplaceCheckoutShellModule } from '@penji/marketplace-checkout/shell';
import { IconDefinition } from '@ant-design/icons-angular';
import { EyeFill, PictureFill } from '@ant-design/icons-angular/icons';
import { NzIconModule } from 'ng-zorro-antd/icon';

const icons: IconDefinition[] = [EyeFill, PictureFill];
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    RouterModule.forRoot([]),
    MarketplaceCheckoutShellModule,
    HttpClientModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideFirestore(() => getFirestore()),
    provideAnalytics(() => getAnalytics()),
    NgxStripeModule.forRoot(environment.stripe_publish_key),
    NzIconModule.forRoot(icons),
  ],
  providers: [{ provide: FIREBASE_OPTIONS, useValue: environment.firebase }],
  bootstrap: [AppComponent],
})
export class AppModule {}
